import * as React from "react";
import JourneyImage from "../../components/JourneyImage";
import JourneyPageLayout, {
  CHAPTERS,
} from "../../components/JourneyPageLayout";
import batCaveImage from "../../images/journey/batcave.jpg";
import cityMobSignImage from "../../images/journey/citymob_sign.jpg";
import citymobToSuperbalistImage from "../../images/journey/citymob_to_superbalist.jpg";
import citymobV2SignImage from "../../images/journey/citymob_v2_sign.jpg";
import dogsImage from "../../images/journey/dogs.jpg";
import mikaelModelingImage from "../../images/journey/mikael_modeling.jpg";
import paintingImage from "../../images/journey/painting.jpg";
import theOldBiscuitMillImage from "../../images/journey/tobm.jpg";
import theOldBiscuitMillStudioImage from "../../images/journey/tobm_studio.jpg";
import tweetImage from "../../images/journey/tweet.jpg";
import wantItAllWarehouse1Image from "../../images/journey/wia_warehouse1.jpg";
import wantItAllWarehouse2Image from "../../images/journey/wia_warehouse2.jpg";
import ogImage from "../../images/og/journey-citymob.jpg";

const JourneyCityMobPage = () => {
  return (
    <JourneyPageLayout
      pageTitle="Matthew Goslett - How We Started CityMob"
      metaDescription="Matthew Goslett shares his experience as the co-founder of a start-up."
      og={{ image: ogImage }}
      chapter={CHAPTERS.CITYMOB}
    >
      <p>
        The diploma was going well and I was keeping busy in my spare time with
        contract development work while waiting tables at Greens Restaurant in
        Constantia. On April 12th, 2011, I responded to a tweet from a friend of
        mine (Skye) who was looking for a part-time PHP developer for this new
        company he was involved in.
      </p>
      <JourneyImage image={tweetImage} />
      <p>
        The following day, I met up with one of the guys for a coffee chat. The
        brief was simple - they had purchased an off-the-shelf web application
        and needed someone to project manage the frontend development and
        integrate that frontend with the backend application. I was off varsity
        for the next 3 months and was very interested in this opportunity, so I
        accepted the job and rocked up for day 1 of work at 6 Pepper Street,
        Cape Town.
      </p>
      <JourneyImage image={[cityMobSignImage, batCaveImage]} />
      <p>
        The company, yet to launch in the market, was called CityMob - a group
        buying website and competitor to Groupon, a global player who had
        acquired South Africa's Twangoo earlier that same year. The business
        model was simple. We'd sign up merchants (restaurants, hotels, spas,
        adventure packages), feature them on the website, market them to our
        database of deal hunters, and sell discounted coupons which could be
        redeemed at those vendors.
      </p>
      <p>
        There were 3 full-time staff at the time, Luke Jedeikin (Managing
        Director), Lior Chen (Head of Sales), and myself (Web Developer). There
        were also 2 other investors & part-time employees, Claude Hanan (Chief
        Financial Officer) and Skye Aspden (Head of Marketing), both of whom
        would later join the company full-time. I joined the company 2 weeks
        before the go-live date, and for those 2 weeks and the next few years,
        we worked flat out through weekends, nights, and public holidays. The
        company was your typical start-up - a tiny cramped office, puppies
        (Bardot and Lilu) running everywhere, late night coding sprints, burger
        and pizza boxes all over, immense pressure, bootstrapping, and employees
        who wore many hats.
      </p>
      <JourneyImage image={[dogsImage, paintingImage]} />
      <p>
        We were all heavily emotionally and/or financially invested in making
        this dream a reality. I focused primarily on website development,
        although I also handled all customer phone calls, live chats, and
        support tickets for the first 6 months. I bought into the vision these
        guys had, the company culture, and the friendship family bonds which
        we'd developed. Luke and I worked very closely together for the first
        years, and we fought like brothers (a lot). I really was working my
        dream job with the most amazing group of people. This led to me having
        to make a rather difficult decision - telling my parents I was dropping
        out of my post-grad to take on full-time work at CityMob. They were
        always extremely supportive of me and the decisions I made, but at the
        same time, I know they had their doubts and concerns. I of course also
        felt incredibly guilty for the wasted study time and the financial
        burden I had placed on them.
      </p>
      <p>
        The company really started to take off by the end of 2011. We'd grown
        our headcount to approximately 10 staff and had brought on copywriters,
        designers, a traffic manager, a support team, and additional sales
        staff. Claude's brother Mikael Hanan had also joined the company as
        Operations Manager after completing his MBA in Tel Aviv, Israel. CityMob
        had become a relatively well-established brand in Cape Town and we'd
        managed to secure deals with some recognisable brands such as HQ,
        Vaudeville Dinner Theatre, and the Vodacom Funny Festival. The company
        was profitable at the time, however we began to feel the heat from the
        saturated market and our ±35 competitors. We needed to look at ways to
        differentiate our offering.
      </p>
      <p>
        A decision was made to pivot away from group buying towards "flash
        sales" (time-limited sales). The company rebranded itself as an
        exclusive, members-only design destination focused on selling designer
        products - art, decor, apparel, homeware, and geekery (gadgets).
      </p>
      <JourneyImage image={citymobV2SignImage} />
      <p>
        We re-built the website in CodeIgniter, my framework of choice at the
        time, and introduced more traditional e-commerce functionality such as a
        product catalogue, shopping cart, and checkout funnel. I'll never forget
        the first product we listed on the website - Melissa Shoes. The shoes
        were catalogued using a combination of supplier stock photography and
        in-office shot sample stack, deep etched on various background colours
        in arbitrary positions. We hadn't quite mastered how to shoot products,
        nor did we have a studio or use any professional models at the time. In
        the photo below, you can see Carla Kotzé (photographer) shooting a pair
        of jeans worn by Mikael (Operations Manager and model).
      </p>
      <JourneyImage image={mikaelModelingImage} />
      <p>
        The company had outgrown the little Pepper Street office so we moved
        operations to the very top floor at the Old Biscuit Mill.
      </p>
      <JourneyImage image={theOldBiscuitMillImage} />
      <p>
        We built a makeshift studio in the middle of the office between the
        developers (myself and Neil Garb) and the kitchen. The model in this
        photo is Wale Oyerinde who was one of our Fashion Buyers at the time.
      </p>
      <JourneyImage image={theOldBiscuitMillStudioImage} />
      <p>
        Berco Express (now Aramex South Africa) did both the warehousing of our
        consignment stock and client deliveries. We'd capture orders in our
        system, send through a nightly spreadsheet to Berco Express, and someone
        on their end would manage the picking, packing, and shipping process. We
        were plagued with logistical issues and frequently struggled with short
        stock, shipping of incorrect orders, late deliveries, and missed
        deliveries. In December 2012, during peak e-commerce season, we reached
        our breaking point. The entire company was tasked to handle support
        tickets and phone calls from a mob of angry clients. Claude and Luke
        immediately started the process of looking for an alternative logistics
        partner.
      </p>
      <p>
        The guys flew up to Johannesburg in early 2013 and met with a company
        called WantItAll. The company, run by brothers Justin Drennan and Ryan
        Drennan and their close friend Terence Murphy, ran their own warehouse
        operation through which they imported and resold Amazon.com products
        locally. They were (and are) a tech savvy group of guys, so tech was
        very prominent in the warehouse. The pickers and packers walked around
        with iPods strapped to their wrists, used to receive picking and packing
        instructions and scan shelves, pallets, and boxes. The box type and size
        was determined by a system which calculated the volumetric weight of the
        items in an order. They plugged into multiple courier APIs and used
        signals such as order time, delivery options, cost, distance, and
        volumetric weight to determine which courier each order would ship with.
      </p>
      <p>
        In April 2013, we sold an equity stake in the company to WantItAll, who
        would take over all our logistics operations. We packed up all our stock
        from the Berco Express warehouse and trucked it to the new distribution
        centre in Johannesburg. I flew up with Vanessa Costopoulos (Supply Chain
        Manager) and together we unpacked, barcoded, and shelved the company's
        entire inventory. The process took a few days, and we wound up missing
        our flight and extending our stay to finish booking in all the stock.
        The 2 of us had to borrow clothes from Justin for our final day of the
        trip.
      </p>
      <JourneyImage
        image={[wantItAllWarehouse1Image, wantItAllWarehouse2Image]}
      />
      <p>
        In July 2013, we started to question the relevance of our brand name,
        and whether it accurately depicted what we had built and where we were
        going. CityMob and Citymob were brands synonymous with group buying.
        This association with deals and bargain hunters was something we
        couldn't shake off. The executive team (mostly Luke) started working on
        a rebrand, the outcome of which was the birth of Superbalist.com.
      </p>
      <JourneyImage image={citymobToSuperbalistImage} />
    </JourneyPageLayout>
  );
};

export default JourneyCityMobPage;
